<template>
  <div id="financingTrack">
    <div class="pages hidden" id="financingTrackPages">
      <div class="page_title"><span class="text hidden" id="financingTrackText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refTrackHolder" v-if="isTrackVisible" class="track_holder">
        <p ref="refTrackDesc"></p>
        <chart-legend ref="refTrackLegend" id="trackLegend" class="common_legend" />
        <double-y-bar-line-chart ref="refTrackDouble" id="trackDouble" customClass="big_trade_double_chart" />
      </div>
      <div ref="refMarketStrategy" v-if="isMarketStrategy">
        <table-chart ref="refMarketStrategyTable" id="MarketStrategyTable" customClass="quote_table_chart" />
      </div>
      <stock-strategy ref="refStockStrategy" v-if="isStockStrategy" />
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import doubleYBarLineChart from "@/components/marketChart/doubleYBarLineChart_v2.0.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import stockStrategy from './stockStrategy.vue'
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      isTrackVisible: false,
      isMarketStrategy: false,
      isStockStrategy: false,
    };
  },
  components: {
    chartLegend,
    doubleYBarLineChart,
    tableChart,
    stockStrategy
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#financingTrackText").addClass("hidden");
        this.isTrackVisible = false
        this.isMarketStrategy = false
        this.isStockStrategy = false
        resolve();
      });
    },
    async show(commandData) {
      this.isTrackVisible = false
      this.isMarketStrategy = false
      this.isStockStrategy = false
      //设置标题
      const title = commandData.data.pageTitle;
      $("#financingTrackPages").removeClass("hidden");
      $("#financingTrackText").html(title);

      this.$nextTick(async () => {
        $("#financingTrackText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#financingTrackText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (commandData.type.indexOf('最佳持股策略') === -1) {
          this.handleTrack(commandData)
        } else if (commandData.type === '市场最佳持股策略') {
          this.handleMarketStrategy(commandData)
        } else {
          this.handleStockStrategy(commandData)
        }
      });
    },
    handleTrack(commandData) {
      this.isTrackVisible = true
      this.$nextTick(() => {
        this.renderTrack(commandData)
      })
    },
    renderTrack(commandData) {
      let width = this.$refs.refTrackHolder.clientWidth;
      this.$refs.refTrackDesc.innerHTML = commandData.data.data.desc
      let data = commandData.data.data.data.map((item) => {
        return {
          label: item.name,
          bar: item.value,
          line: item.lineValue,
        }
      })
      if (data.length > 0) {
        this.$refs.refTrackLegend.createLegend({
          legend: ['买入额（亿）', '买入比'],
          legendType: ['bar', 'dot'],
          colors: ["#FF5A37", "#65BBFF"],
        });
        this.$refs.refTrackDouble.doubleYBarLineChart(data, {
          width: width,
          height: width * 0.66,
          marginLeft: 54,
          marginRight:  54,
          marginTop: 20,
          marginBottom:40,
          duration: 1000,
          xPadding: 0.3,
          ease: "easeCubicIn",
          dotCircleRadius:0,
          y1Suffix: commandData.type === '短期持股策略分析' ? '%' : '',
          y2Suffix: '%',
          xTickNumber: commandData.type === '短期持股策略分析' ? 10 : 3,
          xSuffix: commandData.type === '短期持股策略分析' ? '天' : '',
          textAnchor: commandData.type === '短期持股策略分析' ? 'middle' : ''
        });
      } else {
        this.$refs.refTrackHolder.innerHTML = '暂无数据'
      }
    },
    handleMarketStrategy(commandData) {
      this.isMarketStrategy = true
      this.$nextTick(() => {
        this.renderMarketStrategy(commandData)
      })
    },
    renderMarketStrategy(commandData) {
      let data = [['名称代码', '融资买入比', '持股周期', '胜率', '获利空间']]
      commandData.data.data.vtWinBuySignalRst.forEach((item) => {
        data.push([
          item.stMarginTradeDt.sSecName,
          item.stMarginTradeDt.sBuyPercent,
          `${item.stStockBackTraceRst.iHoldDays}天`,
          item.stStockBackTraceRst.fWinPer,
          item.stStockBackTraceRst.fIncRateAvg
        ])
      })
      let width = this.$refs.refMarketStrategy.clientWidth;
      this.$refs.refMarketStrategyTable.TableChart(data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
        col4Suffix: "%",
        col5Suffix: "%",
      });
      this.updateDate = commandData.data.sDate;
    },
    handleStockStrategy(commandData) {
      this.isStockStrategy = true
      this.$nextTick(() => {
        this.renderStockStrategy(commandData)
      })
    },
    renderStockStrategy(commandData) {
      this.$refs.refStockStrategy.init(commandData)
    }
  },

  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>


<template>
    <div class="stock_strategy">
        <h3>{{ subType }}</h3>
        <ul>
            <li>
                <span>融资买入比</span>
                <span>{{ buyPercent }}</span>
            </li>
            <li>
                <span>持股周期</span>
                <span class="num_rise">{{ iHoldDays }}</span>
            </li>
            <li>
                <span>胜率</span>
                <span>{{ fWinPer }}</span>
            </li>
            <li>
                <span>平均获利</span>
                <span :class="fIncRateAvgClass">{{ fIncRateAvg }}</span>
            </li>
        </ul>
        <p>说明：针对该股的融资买入趋势和历史行情数据回测分析所得，仅供参考！</p>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            subType: '',
            iHoldDays: '',
            fIncRateAvg: '',
            fWinPer: '',
            buyPercent: '',
            fIncRateAvgClass: '',
        }
    },
    methods: {
        init(commandData) {
            this.subType = commandData.subType
            this.iHoldDays = commandData.data.data.iHoldDays
            this.fIncRateAvg = commandData.data.data.fIncRateAvg
            this.fIncRateAvgClass = commandData.data.data.fIncRateAvgClass
            this.fWinPer = commandData.data.data.fWinPer
            this.buyPercent = commandData.data.data.buyPercent
        }
    }
}
</script>
